import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { Fragment } from 'react';

import { Button } from 'src/components/atoms/Button';
import MDXComponents from 'src/components/mdx-components';
import { Column, Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Device } from 'src/embeds/device/Device';
import { FixedImg } from 'src/embeds/image/image-dato';

const mdxComponents = {
  ...MDXComponents,
  // no-op component for Quote since we don't want it rendered
  Quote: () => null,
};

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCaseStudyBlock');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={mdxComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.map(({ id, heading, caseStudy }) => {
          const isBrowserDemo = caseStudy.demoFrame === 'window';

          if (isBrowserDemo) {
            return (
              <Fragment key={id}>
                {!!caseStudy.demoMedia && isBrowserDemo && (
                  <Device data={caseStudy.demoMedia} style={{ marginBottom: '3.5rem' }} />
                )}

                <h5 className="highlighted">Case Study</h5>
                <h2>{heading}</h2>
                {!!caseStudy?.customer?.logo && (
                  <FixedImg
                    constrain
                    width={Math.round(
                      (caseStudy.customer?.logo.width / caseStudy.customer?.logo.height) * 30,
                    )}
                    height={30}
                    data={caseStudy.customer?.logo}
                    style={{ marginBottom: '1.75rem' }}
                  />
                )}
                <MDXRenderer>{caseStudy.contentNode.childMdx.body}</MDXRenderer>
              </Fragment>
            );
          }

          return (
            <Grid key={id} columns="2fr 1fr" spacious>
              <Column>
                <h5 className="highlighted">Case Study</h5>
                <h2>{heading}</h2>
                {!!caseStudy?.customer?.logo && (
                  <FixedImg
                    constrain
                    width={Math.round(
                      (caseStudy.customer?.logo.width / caseStudy.customer?.logo.height) * 30,
                    )}
                    height={30}
                    data={caseStudy.customer?.logo}
                    style={{ marginBottom: '1.75rem' }}
                  />
                )}
                <MDXRenderer>{caseStudy.contentNode.childMdx.body}</MDXRenderer>
              </Column>

              <Column centered>{!!caseStudy.demoMedia && <Device data={caseStudy.demoMedia} />}</Column>
            </Grid>
          );
        })}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
